import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SocialLinks from "./social-links.js"
import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <h1 className="header__title">{siteTitle}</h1>

      <p>I am a passionate web developer and technical team leader.</p>

      <ul className="navigation">
        <li className="navigation__item"><Link to="/">Portfolio</Link></li>
        <li className="navigation__item"><Link to="/about">About</Link></li>
      </ul>

      <SocialLinks />

        <small>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </small>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
