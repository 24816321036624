import React from "react"
import "./mobile-header.css"

const swapMenu = () => {
  const container = document.querySelector('.container')
  container.classList.toggle('is-open');
}

const mobileHeader = ({siteTitle}) => {
  return (
    <header className="mobile-header">
        <button onClick={swapMenu} className="mobile-header__toggle"><img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/bars.svg" alt="" /></button>
        <div className="mobile-header__title">{siteTitle}</div>
    </header>
  )
}

export default mobileHeader