import React from "react"
import "./social-links.css"
import resume from "./BradWadeResumeNovember2019.pdf"

const SocialLinks = () => (
  <ul className="social-links">
    <li className="social-links__item social-links__item--resume">
      <a className="social-links__link" href={resume} download>
        <img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/file-pdf-o.svg" alt="Resume" />
      </a>
    </li>
    <li className="social-links__item social-links__item--linkedin">
      <a className="social-links__link" href="https://www.linkedin.com/in/bradlywade" target="_blank" rel="noopener noreferrer">
        <img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/linkedin-square.svg" alt="LinkedIn" />
      </a>
    </li>
    <li className="social-links__item social-links__item--github">
      <a className="social-links__link" href="https://github.com/bradwade" target="_blank" rel="noopener noreferrer">
        <img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/github-square.svg" alt="GitHub" />
      </a>
    </li>
    <li className="social-links__item social-links__item--codepen">
      <a className="social-links__link" href="https://codepen.io/bradwade/" target="_blank" rel="noopener noreferrer">
        <img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/codepen.svg" alt="CodePen" />
      </a>
    </li>
    <li className="social-links__item social-links__item--drupal">
      <a className="social-links__link" href="https://www.drupal.org/u/bradwade" target="_blank" rel="noopener noreferrer">
        <img src="https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/drupal.svg" alt="Drupal.org" />
      </a>
    </li>
  </ul>
)

export default SocialLinks